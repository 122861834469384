<template>
  <div class="container">
    <div class="academic-outposts">
      <div class="academic-con flex flex-start">
        <div class="area-label">办学点：</div>
        <!-- 省份选择 -->
        <el-select v-model="selectedProv" @change="getCityList(selectedProv)" placeholder="请选择省市">
          <el-option
            v-for="(item, index) in listProv"
            :value="index"
            :label="item.area_name"
            :key="index"
          ></el-option>
        </el-select>

        <!-- 城市选择 -->
        <el-select v-model="selectedCity" @change="getCityId(selectedCity)" placeholder="请选择市区">
          <el-option
            v-for="(item, index) in listCity"
            :value="index"
            :label="item.area_name"
            :key="index"
          ></el-option>
        </el-select>
        <div class="screenBtn" v-preventReClick @click="getSchoolList">筛选</div>
      </div>
    </div>
    <div class="content">
      <ul class="train-ul">
        <li
          class="flex flex-between"
          v-for="item in schoolList"
          :key="item.id"
          @click="openBlank('SchoolDetail', item.school_location_id)"
        >
          <div class="ft-thumb">
            <el-image :src="item.thumb" fit="cover">
              <div slot="error" class="image-slot">
                <img src="@/assets/images/default.png" alt="" />
              </div>
            </el-image>
          </div>
          <div class="rt-text">
            <h3 class="title">
              <el-link :underline="false" :title="item.title">{{ item.title }}</el-link>
            </h3>
            <p>{{ item.content }}</p>
            <div class="view-more">查看详情</div>
          </div>
        </li>
      </ul>
      <el-empty description="暂无数据" v-if="!schoolList.length"></el-empty>
    </div>
  </div>
</template>

<script>
import { deleteField } from "@/utils";
export default {
  data() {
    return {
      selectedProv: null,
      selectedCity: null,
      listProv: [],
      listCity: [],
      provinceId: "",
      cityId: "",
      schoolList: [],
      page: 1,
      totalPage: 0,
      areaInfo: {},
    };
  },
  created() {
    this.getProvList();
    this.getJointEducation();
  },
  methods: {
    //获取省份列表
    getProvList() {
      this.$http.get("/api/web/common/getAgentAreaList").then((res) => {
        this.listProv = res.data.areaList;
      });
    },
    // 获取当前选择省份下的城市列表
    getCityList(key) {
      // 获取当前选择省份的id
      this.provinceId = this.listProv[key].area_code;
      //console.log(this.provinceId)
      this.listCity = this.listProv[key].children;
      this.selectedCity = this.listCity[0].area_name;
      this.cityId = this.listCity[0].area_code; //默认当前城市为列表第一个
    },

    getCityId(key) {
      // 获取当前选择市的id
      this.cityId = this.listCity[key].area_code;
    },

    // 获取联合办学数据
    getJointEducation() {
      this.$http.get("/api/web/news/getJointEducation").then((res) => {
        this.schoolList = res.data.schoolLocationList;
        this.totalPage = res.data.totalPage;
      });
    },
    getSchoolList() {
      const areaInfo = {
        // 当前选择省市的id
        province_id: this.provinceId,
        city_id: this.cityId,
      };
      // console.log(areaInfo);
      this.$http
        .post("/api/web/news/getJointEducation", areaInfo)
        .then((res) => {
          this.schoolList = res.data.schoolLocationList;
          this.totalPage = res.data.totalPage;
        });
    },
    openBlank(name, id) {
      let routeUrl = this.$router.resolve({ name, query: { id } });
      window.open(routeUrl.href, "_blank");
    },
  }
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  .academic-outposts {
    width: 100%;
    padding: 30px 0;
    border-top: solid 1px #f5f5f5;
    background-color: white;
    .academic-con {
      width: var(--container-width);
      margin: 0 auto;
      .area-label {
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        color: #333333;
        line-height: 40px;
      }
      .screenBtn {
        width: 135px;
        height: 40px;
        background: #d71a18;
        font-size: 18px;
        color: #ffffff;
        line-height: 40px;
        border-radius: 20px;
        cursor: pointer;
      }
      .el-select {
        margin-right: 40px;
      }
    }
  }
  .content {
    width: 100%;
    padding: 30px 0;
    margin: 0 auto;
    background-color: #f5f5f5;
    .train-ul {
      width: var(--container-width);
      margin: 0 auto;
      padding: 0;
      li {
        width: 100%;
        height: 375px;
        padding: 20px;
        background: white;
        border-radius: 20px;
        margin-bottom: 30px;
        .ft-thumb {
          width: 470px;
          height: 340px;
          background: #eff3f5;
          border-radius: 10px;
          overflow: hidden;
          .el-image {
            display: block;
            width: 470px;
            height: 340px;
          }
        }
        .rt-text {
          position: relative;
          width: 745px;
          .title {
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            line-height: 32px;
            text-align: left;
            margin: 0;
            .el-link {
              font-size: 24px;
            }
          }
          p {
            font-size: 16px;
            color: #666666;
            line-height: 32px;
            text-align: left;
          }
          .view-more {
            position: absolute;
            bottom: 10px;
            width: 140px;
            height: 48px;
            font-size: 18px;
            line-height: 48px;
            font-weight: bold;
            color: #ffffff;
            background: #d71a18;
            border-radius: 24px;
            cursor: pointer;
          }
        }
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
    .paging {
      width: 100%;
      margin-top: 20px;
      /deep/.el-pager li {
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        background-color: white;
      }
      /deep/.el-pager li:not(.disabled).active {
        background-color: #d71a18;
        color: #fff;
      }
      /deep/.btn-next,
      /deep/.btn-prev {
        background-color: white;
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
      }
    }
    .el-empty {
      width: 1300px;
      margin: 0 auto;
      min-height: calc(100vh - 570px);
      background-color: white;
    }
  }
}
</style>
